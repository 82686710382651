<template>
	<div class="fa">
		<h1>404</h1>
		<h2>Opps, you've reached a dead end! The page your are looking for doesn't exists.</h2>
		<a	
			href="/"
			class="load">
			Go back home
		</a>
	</div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.fa {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	color: #002b51;
	font-family: Roboto;
	
	h1 {
		text-align: center;
		font-size: 80px;
	}
	h2 {
		text-align: center;
		font-size: 20px;
		// margin: 0 60px;
	}
	.load {
	width: rem(300);
	height: rem(110);
	line-height: rem(110);
	text-align: center;
	border-radius: rem(100);
	color: #fff;
	background: #28abfa;
	margin: rem(50) auto;
	font-size: rem(26);
	font-family: Roboto;
	font-weight: 600;
}
}
</style>